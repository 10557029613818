import {Collapse, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon} from '@material-ui/icons';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {FC, useState} from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    selected: {
        color: theme.palette.secondary.main,
        backgroundColor: 'transparent !important',
    },
    nested: {
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },
}));

interface AppMenuLinkProps {
    href: string;
    activeIfExactMatch?: boolean;
    label: string;
    icon: React.ReactElement;
    isExternalLink?: boolean;
    isExpandable?: boolean;
}

const AppMenuLink: FC<AppMenuLinkProps> = ({href, activeIfExactMatch, label, icon, isExternalLink, children}) => {
    const classes = useStyles();
    const router = useRouter();

    isExternalLink = href.startsWith('https://') || href.startsWith('http://') || isExternalLink;
    const isExpandable = Boolean(children);
    const [expanded, setExpanded] = useState<boolean>(router.pathname.startsWith(href));

    const linkIsActive = !isExpandable && (
        href === '/' || activeIfExactMatch
            ? '/' + router.pathname.split('/')[1] === href
            : router.pathname.startsWith(href)
    );

    const button = (
        <ListItem
            button={true}
            component={isExpandable ? 'button' : 'a'}
            selected={linkIsActive}
            classes={{selected: classes.selected}}
            onClick={() => isExpandable && setExpanded(!expanded)}>
            <ListItemIcon>
                {React.cloneElement(icon, {color: linkIsActive ? 'secondary' : 'inherit'})}
            </ListItemIcon>
            <ListItemText primary={label} />
            {isExpandable && (
                expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
            )}
        </ListItem>
    );

    if (isExternalLink) {
        return React.cloneElement(button, {href, target: '_blank'});
    }

    return (
        <>
            {isExpandable ? button : <Link href={href} passHref={true}>{button}</Link>}
            {isExpandable && (
                <Collapse in={expanded}>
                    <List disablePadding={true} className={classes.nested}>
                        {children}
                    </List>
                </Collapse>
            )}
        </>
    );
};

export default AppMenuLink;
