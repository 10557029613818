import {ApolloProvider} from '@apollo/react-hooks';
import {getDataFromTree} from '@apollo/react-ssr';
import DayjsUtils from '@date-io/dayjs';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import * as Sentry from '@sentry/node';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloClient} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import Layout from 'components/layout';
import {PageViewTrackerWithUserId} from 'components/page-view-tracker';
import {SetSentryUser} from 'components/set-sentry-user';
import withApollo from 'next-with-apollo';
import {AppProps} from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import {SnackbarProvider} from 'notistack';
import queryString from 'query-string';
import {useEffect} from 'react';
import * as settings from 'settings';
import {theme} from 'theme';

const {publicRuntimeConfig} = getConfig();

Sentry.init({
    dsn: publicRuntimeConfig.sentryPublicDSN,
    release: publicRuntimeConfig.version,
    environment: publicRuntimeConfig.environment,
    debug: publicRuntimeConfig.environment === 'development',
});

type CustomAppProps = AppProps & {
    apollo: ApolloClient<any>;
    err?: Error;
};

const App = ({Component, pageProps, apollo, err}: CustomAppProps) => {
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles && jssStyles.parentNode) jssStyles.parentNode.removeChild(jssStyles);
    });

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const modifiedPageProps = {...pageProps, err};

    return (
        <>
            <Head>
                <title>{settings.SITE_NAME}</title>
                <meta name='viewport' content='width=device-width, initial-scale=1, user-scalable=no' />
            </Head>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <MuiPickersUtilsProvider utils={DayjsUtils}>
                        <ApolloProvider client={apollo}>
                            <CssBaseline />
                            <Layout>
                                <Component {...modifiedPageProps} />
                            </Layout>
                            <PageViewTrackerWithUserId />
                            <SetSentryUser />
                        </ApolloProvider>
                    </MuiPickersUtilsProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </>
    );
};

export default withApollo(({initialState, ctx, headers}) => {
    const cache = new InMemoryCache().restore(initialState || {});

    const {backendUrl} = getConfig().serverRuntimeConfig;
    const link = new HttpLink({
        uri: `${backendUrl || ''}/graphql/`,
        credentials: 'include',
        headers: {cookie: headers?.cookie},
    });

    let fetchPolicy = 'cache-and-network';
    if (ctx?.query.fetchPolicy) {
        fetchPolicy = ctx?.query.fetchPolicy as string;
    } else if (typeof window !== 'undefined') {
        const query = queryString.parse(window.location.search);
        if (query.fetchPolicy) fetchPolicy = query.fetchPolicy as string;
    }

    return new ApolloClient({
        cache,
        link,
        ssrMode: typeof window === 'undefined',
        defaultOptions: {
            watchQuery: {
                fetchPolicy: fetchPolicy as any,
                notifyOnNetworkStatusChange: true,
            },
        },
    });
}, {
    // Wrapper to catch some SSR errors and log them to Sentry.
    async getDataFromTree(tree, context) {
        try {
            const result = await getDataFromTree(tree, context);
            return result;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    },
})(App);
