import * as Sentry from '@sentry/node';
import {useGetUserQuery} from 'generated/graphql';
import {FC, useEffect} from 'react';

export const SetSentryUser: FC = () => {
    const {data} = useGetUserQuery();
    useEffect(() => {
        if (data?.user) {
            Sentry.setUser({
                id: data.user.id,
                email: data.user.email,
            });
        } else {
            Sentry.setUser(null);
        }
    }, [data]);
    return null;
};
