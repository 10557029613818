import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

interface CustomPaginationArrowsProps {
  page: number;
  totalPages: number;
  onChangePage: (newPage: number) => void;
  siblingCount: number;
}


const CustomPaginationArrows: React.FC<CustomPaginationArrowsProps>  = ({ page, totalPages, onChangePage,  siblingCount }) => {
  return (
    <div>
      <IconButton
        onClick={() => onChangePage(page - 1)}
        disabled={page === 0}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={() => onChangePage(page + 1)}
        disabled={page >= totalPages - 1}
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

export default CustomPaginationArrows;
