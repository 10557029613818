import { Button, createStyles, IconButton, makeStyles, Menu, MenuItem, Theme, useMediaQuery } from '@material-ui/core';
import { AccountCircle as AccountCircleIcon, Notifications as NotificationsIcon } from '@material-ui/icons';
import { useGetNotificationsUserQuery, useGetUserQuery } from 'generated/graphql';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useState, useEffect } from 'react';
import * as settings from 'settings';
import Notifications from './notification';

const useStyles = makeStyles(() => createStyles({
    root: {
        display: 'flex',
    },
    notificationCount: {
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '50%',
        padding: '5px 8px',
        fontSize: '12px',
        fontWeight: 'bold',
    },
}));

const UserMenu: FC = () => {
    const classes = useStyles();
    const router = useRouter();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [showNotifications, setShowNotifications] = useState(false);


    const { data: { user } = {} } = useGetUserQuery();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        handleClose();
        router.push(settings.LOGOUT_URL);
    };



    const { data } = useGetNotificationsUserQuery();
    const notificationsUser = data ? data.notificationsUser : null;
    const [notifications, setNotifications] = useState(notificationsUser || []);
    const unseenNotifications = notifications.filter((notification: any) => !notification.seen);
    const unseenCount = unseenNotifications.length;

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (showNotifications && !event.target.closest('.notifications-container')) {
                setShowNotifications(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showNotifications]);

    const handleNotificationClick = () => {
        const updatedNotifications = notifications.map(notification => ({
            ...notification,
        }));

        setNotifications(updatedNotifications);
        setShowNotifications(!showNotifications);
    };

    if (!user) return null;

    return (
        <div className={classes.root}>
            {user && (
                <div className="notifications-container">
                    <IconButton onClick={handleNotificationClick} color='primary' >
                        {unseenCount > 0 && (
                            <div className={classes.notificationCount}>{unseenCount} </div>)}
                        <NotificationsIcon />
                    </IconButton>
                    {showNotifications && (
                        <Notifications notifications={notifications} />

                    )}
                    {smDown ?
                    <IconButton onClick={handleOpen} color='primary'><AccountCircleIcon /></IconButton>
                    : <Button onClick={handleOpen} color='primary' startIcon={<AccountCircleIcon />}>Hola, {user.firstName}!</Button>} 
                </div>
            )}
            {!user && (
                <Link href={settings.LOGIN_URL} passHref={true}>
                    <Button color='primary'>
                        Login
                    </Button>
                </Link>
            )}
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <li>
                    <Link href='/users/profile' passHref={true}>
                        <MenuItem onClick={handleClose} component='a'>Perfil</MenuItem>
                    </Link>
                </li>
                <MenuItem onClick={handleLogout}>Salir</MenuItem>
            </Menu>
        </div>
    );
};

export default UserMenu;
