import { Message as MessageIcon } from '@material-ui/icons';
import {
    Typography, createStyles, makeStyles, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Button
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { formatDate } from 'core/locale';
import { UpdateNotificationAllMutationInput, UpdateNotificationUserMutationInput, useUpdateNotificationAllMutation, useUpdateNotificationUserMutation } from 'generated/graphql';
import CustomPaginationArrows from 'core/components/async-table/custom-pagination';
import { Player } from '@lottiefiles/react-lottie-player';
import NotificationEmpty from 'gift/empty-notifications.json';

const useStyles = makeStyles(() => createStyles({
    root: {
        display: 'flex',
    },
    notificationRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
    },
    markAllButton: {
        padding: '8px 16px',
        fontSize: 'smaller !important',
        cursor: 'pointer',
    },
    notificationsContainer: {
        position: 'absolute',
        top: '100%',
        right: 0,
        backgroundColor: 'white',
        padding: '10px',
        width: '30%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    notification: {
        marginBottom: '5px',
        padding: '8px',
        borderBottom: '1px solid #eee',
        borderRadius: '4px',
        color: 'black'
    },
    account: {
        display: 'flex',
        alignItems: 'center'
    },
    date: {
        color: 'grey'
    },
    notificationIcon: {
        display: 'flex',
        alignItems: 'center'
    },
    userName: {
        marginLeft: '5px',
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    '@media (max-width: 768px)': {
        notificationsContainer: {
            width: '100%'
        },
    },
}));


interface NotificationsProps {
    notifications: any[]
}


const Notifications: FC<NotificationsProps> = ({ notifications }) => {
    const classes = useStyles();
    const [updateNotificationUser, { error: mutationError }] = useUpdateNotificationUserMutation();
    const [updateNotificationAll, { error: error }] = useUpdateNotificationAllMutation();
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    const currentNotifications = notifications?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const handleNotificationClick = async (notification: any) => {
        const updateNotificationData = {
            id: notification.id,
            seen: true,
            notificationId: notification.notificationId.id,
            userNotified: notification.userNotified.id
        };

        try {
            const response = await updateNotificationUser({
                variables: { input: updateNotificationData as UpdateNotificationUserMutationInput },
            });
        } catch (error) {
            // console.error(error);
        }
    };
    const markAsRead = async () => {

        const updateNotificationAllData = {
            id: notifications[0].id,
            notificationId: notifications[0].notificationId.id,
            userNotified: notifications[0].userNotified.id
        };
        try {
            const response = await updateNotificationAll({
                variables: { input: updateNotificationAllData as UpdateNotificationAllMutationInput },
            });
            window.location.reload();
        } catch (error) {
            // console.error(error);
        }

    }

    return (
        <div className={classes.notificationsContainer}>
            <div className={classes.notificationRow}>
                <Typography variant="h6" color="primary">Notificaciones</Typography>
                {notifications.length > 0 && <Button className={classes.markAllButton} onClick={() => markAsRead()} >Marcar todas como leídas</Button>}
            </div>
            <TableContainer>
                {currentNotifications && currentNotifications.length > 0 ? (
                    <Table>
                        <TableBody>
                            {currentNotifications?.slice(0, 5).map((not: any, index) => (
                                <TableRow key={index}>
                                    <TableCell size='small'>
                                        <div className={classes.notificationIcon}>
                                            <MessageIcon color='secondary' />
                                            <div className={classes.userName}>{not.notificationId.createdBy.displayName} </div>
                                        </div>
                                        <div key={index} onClick={() => handleNotificationClick(not)} style={{ cursor: 'pointer' }}>
                                            {not.notificationId.sale != null && <a href={`/sales/${not.notificationId.sale.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                {not.notificationId.text}
                                            </a>}
                                        </div>
                                        <Typography variant="caption" color='textSecondary'>
                                            {formatDate(not.notificationId.created)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell size='small'>
                                        <div>
                                            {not.seen == false && <div style={{ width: '10px', height: '10px', background: 'red', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }}></div>}
                                        </div>
                                    </TableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                    <Player
                        autoplay
                        loop
                        src={NotificationEmpty}
                        style={{ height: '250px', width: '250px' }}
                    />
                    <Typography variant="body1" color='textSecondary'>
                        No tenés notificaciones.
                    </Typography>
                </div>
                )}
            </TableContainer>
            <div className={classes.paginationContainer}>
                <CustomPaginationArrows
                    page={page}
                    totalPages={Math.ceil((notifications?.length || 0) / rowsPerPage)}
                    onChangePage={handleChangePage}
                    siblingCount={1}
                />
            </div>
        </div>

    );
};

export default Notifications;