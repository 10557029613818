import {
    AppBar, Drawer, Hidden, IconButton, SwipeableDrawer, Toolbar, useMediaQuery, useScrollTrigger,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import { GraphQLErrors } from 'core/components/graphql-errors';
import { formatDateTime } from 'core/locale';
import { useGetUserQuery } from 'generated/graphql';
import React, { FC, useState } from 'react';
import * as settings from 'settings';
import {useRouter} from 'next/router';
import AppMenu from './app-menu';
import UserMenu from './user-menu';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        background: 'white'
    },
    main: {
        // flexGrow: 1,
        width: '100%',
        padding: theme.spacing(3),
        overflowX: 'auto',
    },
    appBar: (props: any) => ({
        zIndex: theme.zIndex.drawer + 1,
        background: theme.palette.background.paper,
        
    }),
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logo: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        '& img': {
            width: 'auto',
            height: '60px',
        },
    },
    drawer: {
        width: drawerWidth,
        border: 'white'
    },
    drawerPaper: {
        width: drawerWidth,
        border: 'white'
    },
    toolbarSpacer: {
        ...theme.mixins.toolbar,
    },
    printHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
    const [drawerOpened, setDrawerOpened] = useState(false);
    const elevateAppBar = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
    const classes = useStyles({ elevateAppBar });
    const print = useMediaQuery('print');
    const router = useRouter();
    const { data, error, refetch } = useGetUserQuery();
    const user = data?.user;

    

    const Logo = () => (
        <div className={classes.logo}>
            <img src={require('./assets/logo.jpg')} alt={settings.SITE_NAME} />
        </div>
    );

    if (print) {
        return (
            <>
                <div className={classes.printHeader}>
                    <Logo />
                    {formatDateTime()}
                </div>
                {children}
            </>
        );
    }

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} elevation={elevateAppBar ? 4 : 0}>
                <Toolbar>
                    {user && (
                        <Hidden mdUp={true}>
                            <IconButton
                                className={classes.menuButton}
                                edge='start'
                                color='primary'
                                onClick={() => setDrawerOpened(true)}>
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                    )}
                    <Logo />
                    <UserMenu />  
                    {/* {router.asPath == '/users/login' &&  (<aside className={classes.aside}>
                    </aside>)
                    } */}
                </Toolbar>
            </AppBar>
            {user && (
                <>
                    <Hidden mdUp={true}>
                        <SwipeableDrawer
                            className={classes.drawer}
                            classes={{ paper: classes.drawerPaper }}
                            keepMounted={true}
                            open={drawerOpened}
                            onOpen={() => setDrawerOpened(true)}
                            onClose={() => setDrawerOpened(false)}>
                            <AppMenu onClose={() => setDrawerOpened(false)} />
                        </SwipeableDrawer>
                    </Hidden>
                    <Hidden smDown={true} implementation='css'>
                        <Drawer
                            open={true}
                            variant='persistent'
                            className={classes.drawer}
                            classes={{ paper: classes.drawerPaper }}>
                            <div className={classes.toolbarSpacer} />
                            <AppMenu />
                        </Drawer>
                    </Hidden>
                </>
                
            )}
            <main className={classes.main}>
                <div className={classes.toolbarSpacer} />
                {error ? <GraphQLErrors error={error} refetch={refetch} /> : children}
            </main>
        </div>
    );
};

export default Layout;
